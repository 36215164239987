<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        结算记录
      </template>
      <template #input>
        <a-input
          style="width: 300px"
          v-model="channelName"
          placeholder="请输入渠道方名称"
        />
        <a-range-picker
          @change="changeStartTime"
          style="width: 380px"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['结算开始时间','结算结束时间']"
        />
        <a-date-picker
          v-model="year" 
          :open='pickerShow'  
          @openChange="openChange" 
          @panelChange="panelChange" 
          @change="clearChange"
          placeholder="年度" 
          format="YYYY" 
          style="width:100px;margin-right:0"
          class="picker" 
          mode="year" />
        <a-select
          placeholder="季度"
          v-model="quarter"
          style="width:100px"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1"> 第一季度 </a-select-option>
          <a-select-option :value="2"> 第二季度 </a-select-option>
          <a-select-option :value="3"> 第三季度 </a-select-option>
          <a-select-option :value="4"> 第四季度 </a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="btn"
          @click='onSearch()'
        >搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index)=>index"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="billingAmount" slot-scope="text">
            ￥{{ text.toFixed(2) }}
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="goPage(row)">订单明细</a>
            <a v-show="row.billingFile" @click="showUrl(row.billingFile)"> | 查看附件</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { api as viewerApi } from "v-viewer"

const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "渠道方",
    align: "center",
    dataIndex: "channelName",
  },
  {
    title: "结算说明",
    align: "center",
    width: 300,
    dataIndex: "billingInstructions",
  },
  {
    title: "结算金额",
    align: "center",
    dataIndex: "billingAmount",
    scopedSlots: { customRender: "billingAmount" },
  },
  {
    title: "经手人",
    align: "center",
    dataIndex: "handler",
  },
  {
    title: "结算时间",
    align: "center",
    dataIndex: "billingTime",
  },
  {
    title: "结算季度",
    align: "center",
    dataIndex: "billingQuarter",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      channelName:'',
      starttime:'',
      endtime:'',
      billingQuarter:'',
      year: null,
      quarter:undefined,
      pickerShow:false,
    };
  },
  // 事件处理器
  methods: {
    moment,
    clearChange(e){
      this.year = null
    },
    panelChange(e){
      let time = moment(e).format("YYYY");
      this.year = time
      this.pickerShow = false
    },
    openChange(e){
      this.pickerShow = true
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    onSearch() {
      this.pageNumber = 1
      this.getManageList();
    },
    getManageList() {
      if (this.year && !this.quarter || !this.year && this.quarter) {
        return this.$message.warning("请确认结算周期数据是否完整");
      }
      this.loading = true
      this.$ajax({
        method: 'GET',
        url: '/hxclass-management/channel/billing/list',
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          channelName: this.channelName,
          start: this.starttime,
          end: this.endtime,
          billingQuarter: (this.year ? this.year + '-' + this.quarter : undefined)
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    changeStartTime(x, s) {
      this.starttime = s[0];
      this.endtime = s[1];
    },
    // 查看订单明细
    goPage(e) {
      this.$router.push({ path: "/admin/channelRecordDetail", query: { billingId: e.id, channelName: e.channelName, channelCode: e.channelCode } })
    },
    // 预览文件
    showUrl(url) {
      var array = url.split(',');
      const $viewer = viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: 0
        },
        images: array
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
</style>
